@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Rubik+Mono+One&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    overflow: overlay;
}

*::-webkit-scrollbar {
    display: block;
    width: 16px;
}

*::-webkit-scrollbar-button {
    display: none;
}

*::-webkit-scrollbar-track, *::-webkit-scrollbar-track-piece {
    background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
    background-color: #00000000;
    border-radius: 24px;
    border: 5px solid transparent;
    box-shadow: 4px 0px 0px 4px #f1e1e140 inset;
}

*::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    box-shadow: 6px 0px 0px 6px #e1e1f140 inset;
}