/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #ffffff;
    color: #ffffff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: #ffffff;
    }
    50%, 100% {
      background-color: rgba(136, 129, 123, 0.2)
    }
  }