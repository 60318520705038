@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap");

:root {
    --main-body: #3ca7f5;
    --fins: #b4d3e9;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stage {
    width: 15em;
    height: 15em;
    position: relative;
    transform: rotate(45deg);
    font-size: 16px;
    animation: shake 1s linear infinite alternate-reverse;
}


@keyframes shake {
    0% { transform: translate(0, 0) rotate(40deg); }
    25% { transform: translate(5px, 5px) rotate(40deg); }
    50% { transform: translate(0, 0) rotate(40deg); }
    75% { transform: translate(-5px, 5px) rotate(40deg); }
    100% { transform: translate(0, 0) rotate(40deg); }
  }


.body {
    height: 8em;
    width: 4em;
    background-color: var(--main-body);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top {
    position: absolute;
    height: 0;
    width: 0;
    top: -3em;
    border-left: 2em solid transparent;
    border-right: 2em solid transparent;

    border-bottom: 6em solid var(--main-body);
    left: 50%;
    transform: translate(-50%, -50%);
}

.glass {
    position: absolute;
    height: 2.5em;
    width: 2em;
    background-color: #000;
    border-radius: 20px;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.boster {
    position: absolute;
    height: 6em;
    width: 1.75em;
    background-color: var(--fins);

    border-bottom: 5em solid var(--fins);
    border-radius: 7px;
    bottom: -0.75em;
    z-index: 2;
}

.bosterL {
    border-left: 0.5em solid transparent;
    border-radius: 20px 7px 7px 7px;
    left: -1.375em;
}

.bosterR {
    border-right: 0.5em solid transparent;
    border-radius: 7px 20px 7px 7px;
    right: -1.375em;
}

.ring {
    position: absolute;
    height: 2em;
    width: 4em;
    background-color: #275792;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fire {
    position: absolute;
    height: 5em;
    width: 2.75em;
    bottom: -5em;
    display: flex;
    left: 0.625em;
    justify-content: space-around;
    z-index: 0;
}

.flame {
    position: relative;
    top: -0.3em;
    height: 5em;
    width: 0.5em;
    background-color: #f6a728;
    border-radius: 1px;
}

.flame.h1 {
    height: 5em;
    animation: flameGrowH1 0.23s linear 0s infinite alternate;
}

.flame.h2 {
    height: 9em;
    animation: flameGrowH2 0.17s linear 0s infinite alternate-reverse;
    background-color: #f66928;
}

.flame.h3 {
    height: 5em;
    animation: flameGrowH3 0.2s linear 0s infinite alternate-reverse;
}

@keyframes flameGrowH1 {
    0% {
        height: 3.25em;
    }

    100% {
        height: 5em;
    }
}

@keyframes flameGrowH2 {
    0% {
        height: 9.5em;
    }

    100% {
        height: 7em;
    }
}

@keyframes flameGrowH3 {
    0% {
        height: 3.25em;
    }

    100% {
        height: 5em;
    }
}